@tailwind base;
@tailwind components;
@tailwind utilities;



.masonaryDiv > div{
  margin-left: auto;
  margin-right: auto;
}

.bidVal {
  white-space: pre-line;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Dela Gothic One', cursive;
}

.header-title {
  font-family: 'Karantina', cursive;
}

.sub-title {
  font-family: 'Anek Odia', sans-serif;
}

.button-text {
  font-family: 'Archivo Black', sans-serif;
}